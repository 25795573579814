<template>
    <div
        class="wicket"
        v-if="isShow"
    >
        <div class="wicketBox">
            <div class="wicket_title">
                <p>
                    <!--  @blur="editSessionName" -->
                    <span id="newSessionName" @click="editName">{{ sessionName }}</span>
                    <img
                        v-if="scene==='team'"
                        @click="showTeamMember()"
                        src="../../../../images/Open.png"
                    >
                </p>
                <!-- <button></button> -->
                <ul :class="{active:isShowTeamMember}" class="PersonnelList" v-if="scene==='team'">
                    <li
                        v-for="memberItem in membersParse"
                        :title="memberItem.alias"
                        :key="memberItem.id"
                        @mouseover="changeActive($event,memberItem.alias)"
                        @mouseout="removeActive($event,memberItem.alias)"
                        @click="removeMember(memberItem)"
                    >
                        {{ memberItem.alias|getLastCharacter }}
                        <!-- <span class="remove">移除</span> -->
                    </li>
                    <li class="AddTo" @click="handleAddTeamMember">
                        <span class="iconfont iconno-select-add"></span>
                    </li>
                </ul>
            </div>
            <div class="wicket_content">
                <div class="ProjectInfo" v-if="isShowCard">
                    <div class="xx">
                        <p>1</p>
                        <p>青岛中联公司送料申请</p>
                        <p>2018-12-18 18:33</p>
                    </div>
                    <div class="personnel">
                        <span>事项：</span>
                        <ul>
                            <li>送料</li>
                        </ul>
                    </div>
                    <button class="ViewDetails" ng-click="OpenMatterWizard($event,BusinessInstanceInfo)"></button>
                </div>
                <div class="DialogBox" :class="{ active:isShowCard }">
                    <ul id="chatContent">
                        <li
                            v-for="msgItem in msglist"
                            :key="msgItem.idClient"
                            :class="{'Info_l':msgItem.flow=='in','Info_r':msgItem.flow=='out','remind':msgItem.type=='notification'}"
                        >
                            <div
                                class="InfoTime"
                                v-if="msgItem.type=='timeTag'"
                            >
                                <strong>---- {{ msgItem.showText }} ----</strong>
                            </div>
                            <div
                                class="HeadPortrait"
                                v-if="msgItem.type!=='notification'&&msgItem.type!=='timeTag'"
                            >
                                {{ msgItem.fromNick|getLastCharacter }}
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='text'"
                            >
                                <span>{{ msgItem.fromNick }}</span>
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p v-html="msgItem.showText"></p>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='notification'"
                            >
                                <p>{{ msgItem.showText }}</p>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='image'"
                                @click.stop="showFullImg(msg.originLink)"
                            >
                                <p>
                                    <img :src="msgItem.file.url + '?imageView&thumbnail=180x0&quality=85'">
                                </p>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='file'"
                                @click.stop="showFullImg(msg.originLink)"
                            >
                                <p>
                                    <a :href="msgItem.fileLink" target="_blank" class="clearfix">
                                        <i class="iconfont iconwenjian2 fl"></i>
                                        <span class="fl">{{ msgItem.showText }}</span>
                                    </a>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div class="chat-bottom">
                        <div class="tool">
                            <div
                                id="emojiTag"
                                class="m-emojiTag"
                                v-if="isEmojiShown"
                            >
                                <div
                                    class="m-emoji-wrapper m-chat-emoji"
                                    style="width: 420px; height: 300px;"
                                >
                                    <chat-emoji
                                        :type="session.type"
                                        :scene="session.scene"
                                        :to="session.to"
                                        @add-emoji="addEmoji"
                                        @hide-emoji="hideEmoji"
                                    ></chat-emoji>
                                </div>
                            </div>
                            <span class="Expression" @click="showEmoji">
                                <span class="iconfont iconbiaoqing"></span>
                            </span>
                            <span class="Expression" :class="{currVoice: isVoice}" @click="showVoice">
                                <span class="iconfont iconyuyin"></span>
                            </span>
                            <span class="file">
                                <span class="iconfont iconwenjianjia"></span>
                                <input
                                    multiple="multiple"
                                    type="file"
                                    name="file"
                                    id="uploadFile"
                                    ref="fileToSent"
                                    @change="sendFileMsg"
                                >
                            </span>
                        </div>
                        <textarea v-if="!isVoice" v-model="msgToSent"></textarea>
                        <el-popover
                            v-else
                            placement="top"
                            width="200"
                            trigger="click"
                        >
                            <div slot="reference" class="voice_box">
                                <div class="voice">
                                    <div class="icon_box">
                                        <i class="iconfont icona-24gf-playCircle"></i>
                                    </div>
                                    <div class="icon_txt">
                                        继续录制
                                    </div>
                                </div>
                            </div>
                            <div class="voice_content">
                                <div class="title">
                                    单击开始录音，最长可录制
                                </div>
                                <div class="mic_icon">
                                    <i @click="recordFun" class="iconfont iconyuyin"></i>
                                </div>
                                <div class="time">
                                    {{ timeNum }}′′
                                </div>
                                <div class="btns">
                                    <div class="btn">
                                        <div @click="destroyRecorder" class="btn_box">
                                            <i class="iconfont icona-guanbi1"></i>
                                        </div>
                                        <div class="btn_txt">
                                            取消重录
                                        </div>
                                    </div>
                                    <div class="btn">
                                        <div @click="pauseRecorder" class="btn_box">
                                            <i class="iconfont iconzanting"></i>
                                        </div>
                                        <div class="btn_txt">
                                            暂停录制
                                        </div>
                                    </div>
                                    <div class="btn">
                                        <div @click="stopRecorder" class="btn_box">
                                            <i class="iconfont iconduigouxiao"></i>
                                        </div>
                                        <div class="btn_txt">
                                            发送语音
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-popover>
                        <form
                            action="#"
                            id="uploadForm"
                            class="submit-form"
                        >
                            <input
                                type="button"
                                @click="sendTextMessage()"
                                value="发送"
                                class="button"
                            >
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="请输入名称"
            :visible.sync="editNameDialog"
            :close-on-click-modal="false"
            center
            class="option-dialog"
            :modal="false"
        >
            <div>
                <el-input type="text" v-model="newName"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editSessionName">确定</el-button>
                <el-button @click="editNameDialog = false">取 消</el-button>
            </span>
        </el-dialog>
        <teamChoseMembers
            :show-dialogue="showTeamChoseDialogue"
            :dialogue-tit="'添加群组成员'"
            @update-show="updateShowDialogue"
            @sure-members="sureMembers"
            :type="'addTeamMember'"
            :members="existMembers"
        ></teamChoseMembers>
    </div>
</template>
<script>
/* eslint-disable */
import util from '@/common/NIM/utils';
import config from '@/common/NIM/configs';
import emojiObj from '@/common/NIM/configs/emoji';
import ChatEmoji from './ChatEmoji'
import teamChoseMembers from './../teamChoseMembers';
import Recorder from 'js-audio-recorder';
import lamejs from 'lamejs'
export default {
    components: {
        ChatEmoji,
        teamChoseMembers,
    },
    props: {
        isShow: {
            type: [Boolean],
        },
        session: {
            type: [Object, String],
        },
        chatdata: {
            type: [Object],
        },
    },
    data() {
        return {
            msgToSent: '',
            isShowTeamMember: false,
            isEmojiShown: false,
            sessionId:'',
            isShowCard: false,
            editNameDialog: false,
            newName: '',
            showTeamChoseDialogue: false,
            existMembers: [],
            membersParse: [],

            isVoice: false,
            timeNum: 60,
            recorder: null,
            timeInter: null,

            aliyunOssToken: null,
            buCode: 'Flow',
        };
    },
    updated() {
        // 滚动到底部
        this.$nextTick(function () {
            setTimeout(() => {
                const div = document.getElementById('chatContent');
                div.scrollTop = div.scrollHeight;
            }, 100);
        });
    },
    computed: {
        msglist() {
            const msgs = window.$globalHub.$store.state.currSessionMsgs;
            const newMsgs = msgs.map(item => {
                const newItem = this.computedItem(item);
                return newItem;
            });
            return newMsgs;
        },
        sessionName() {
            const sessionId = this.sessionId;
            let user = null;
            if (/^p2p-/.test(sessionId)) {
                user = sessionId.replace(/^p2p-/, '');
                if (user === window.$globalHub.$store.state.userUID) {
                    return '我';
                } if (this.isRobot) {
                    return this.robotInfos[user].nick;
                }
                let name = '';
                if(window.$globalHub.$store.state.userInfos[user]){
                    const userInfo = window.$globalHub.$store.state.userInfos[user];
                    name = util.getFriendAlias(userInfo);
                } else {
                    name = this.chatdata.name||this.chatdata.friend_name;
                }
                return name;
            }    
                
            if (/^team-/.test(sessionId)) {
                if (this.teamInfo) {
                    // teamInfo中的人数为初始获取的值，在人员增减后不会及时更新，而teamMembers在人员增减后同步维护的人员信息
                    const members = window.$globalHub.$store.state.teamMembers && window.$globalHub.$store.state.teamMembers[this.teamInfo.teamId];
                    const memberCount = members && members.length;
                    // return this.teamInfo.name + (memberCount ? `(${memberCount})` : '');
                    return this.teamInfo.name;
                }
                return '群';
            }
        },
        scene() {
            return util.parseSession(this.sessionId).scene;
        },
        to() {
            return util.parseSession(this.sessionId).to;
        },
        // 判断是否是机器人
        isRobot() {
            const sessionId = this.sessionId;
            let user = null;
            if (/^p2p-/.test(sessionId)) {
                user = sessionId.replace(/^p2p-/, '');
                if (this.robotInfos[user]) {
                    return true;
                }
            }
            return false;
        },
        myInfo() {
            return window.$globalHub.$store.state.myInfo;
        },
        userInfos() {
            return window.$globalHub.$store.state.userInfos;
        },
        robotInfos() {
            return window.$globalHub.$store.state.robotInfos;
        },
        teamInfo() {
            if (this.scene === 'team') {
                const teamId = this.sessionId.replace('team-', '');
                return window.$globalHub.$store.state.teamlist.find(team => {
                    return team.teamId === teamId;
                });
            }
            // eslint-disable-next-line no-undefined
            return undefined;
        },
        muteInTeam() {
            if (this.scene !== 'team') {return false;}
            const teamMembers = window.$globalHub.$store.state.teamMembers;
            const Members = teamMembers && teamMembers[this.teamInfo.teamId];
            const selfInTeam = Members && Members.find(item => {
                return item.account === window.$globalHub.$store.state.userUID;
            });
            return selfInTeam && selfInTeam.mute || false;
        },
        teamInvalid() {
            if (this.scene === 'team') {
                return !(this.teamInfo && this.teamInfo.validToCurrentUser);
            }
            return false;
        },
        sendInvalidHint() {
            if (this.scene === 'team' && this.teamInvalid) {
                return `您已不在该${this.teamInfo && this.teamInfo.type === 'normal' ? '讨论组' : '群'}，不能发送消息`;
            } if (this.muteInTeam) {
                return '您已被禁言';
            }
            return '无权限发送消息';
        },
        members() {
            var members = window.$globalHub.$store.state.teamMembers[this.sessionId.split('-')[1]];
            var userInfos = window.$globalHub.$store.state.userInfos;
            var needSearchAccounts = [];
            if (members) {
                members = members.map( item => {
                var member = Object.assign({}, item) //重新创建一个对象，用于存储展示数据，避免对vuex数据源的修改
                member.valid = true //被管理员移除后，标记为false
                if (member.account === window.$globalHub.$store.state.userUID) {
                    member.alias = '我'
                    member.avatar = window.$globalHub.$store.state.myInfo.avatar
                    this.isOwner = member.type === 'owner'
                    this.hasManagePermission = member.type !== 'normal'
                } else if (userInfos[member.account] === undefined) {
                    needSearchAccounts.push(member.account)
                    member.avatar = member.avatar || this.avatar
                    member.alias = member.nickInTeam || member.account
                } else {
                    member.avatar = userInfos[member.account].avatar
                    member.alias = member.nickInTeam ||userInfos[member.account].nick
                }
                return member
                })
                // if (needSearchAccounts.length>0 && !this.hasSearched) {
                // this.hasSearched = true
                // while(needSearchAccounts.length>0) {
                //     this.searchUsers(needSearchAccounts.splice(0, 150))
                // }
                // }
                return members
            }
            return []
        }
    },
    watch: {
        session(val) {
            this.sessionId = val;
            // 此时设置当前会话
            window.$globalHub.$store.dispatch('setCurrSession', this.sessionId);
            window.$globalHub.$store.dispatch('getHistoryMsgs', {scene:this.scene,to:this.to});
            // 获取群成员
            if (this.scene === 'team') {
                const teamMembers = window.$globalHub.$store.state.teamMembers[this.to];
                // eslint-disable-next-line no-undefined
                if (teamMembers === undefined || teamMembers.length < this.teamInfo.memberNum) {
                    window.$globalHub.$store.dispatch('getTeamMembers', this.to);
                }
            }
        },
    },
    mounted() {
        this.getOssToken();
        this.init();
    },
    methods: {
        // 初始化
        init() {
            this.recorder = new Recorder({
            // 采样位数，支持 8 或 16，默认是16
                sampleBits: 16,
                // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值
                sampleRate: 48000,
                // 声道，支持 1 或 2， 默认是1
                numChannels: 1,
                // 是否边录边转换，默认是false
                compiling: false,
            });
        },
        recordFun() {
            this.startRecorder();
        },
        // 开始录音
        startRecorder() {
            if (this.timeNum !== 60) {
                return;
            }
            const that = this;
            this.recorder.start().then(() => {
                that.timeInter = setInterval(() => {
                    if (that.timeNum > 0) {
                        that.timeNum -= 1;
                    } else {
                        that.$message({
                            showClose: true,
                            message: '录制完成',
                            type: 'success',
                        });
                        clearInterval(that.timeInter);
                    }
                }, 1000);
            },
            error => {
                // 出错了
                console.log(`${error.name} : ${error.message}`);
            },
            );
        },
        // 暂停录音
        pauseRecorder() {
            this.recorder.pause();
            clearInterval(this.timeInter);
            this.timeInter = null;
        },
        // 结束录音
        stopRecorder() {
            this.recorder.stop();
            clearInterval(this.timeInter);
            this.timeInter = null;
            console.log(this.recorder, 'this.recorder');
            this.sendRecordFun();
        },
        // 销毁录音
        destroyRecorder() {
            this.recorder.destroy().then(() => {
                clearInterval(this.timeInter);
                this.timeNum = 60;
                this.timeInter = null;
                this.init();
            });
        },
        sendRecordFun() {
            let  file = this.convertToMp3(this.recorder.getWAV());
            const name = new Date().getTime() + '.mp3';
            file = new File([file], name);
            this.upGroupFun(file, name);
            // 上传，发送
            // this.$axios.post('http://172.17.xx.xxx:8080/visit/uploadFile', formData).then(res => {
            //     console.log('res---', res)
            // })
        },
        convertToMp3(wavDataView) {
            // 获取wav头信息
            const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
            const { channels, sampleRate } = wav;
            console.log('wav', wav);
            const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
            // 获取左右通道数据
            const result = this.recorder.getChannelData();
            const buffer = [];
            
            const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
            const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
            const remaining = leftData.length + (rightData ? rightData.length : 0);
            
            const maxSamples = 1152;
            for (let i = 0; i < remaining; i += maxSamples) {
                const left = leftData.subarray(i, i + maxSamples);
                let right = null;
                let mp3buf = null;
            
                if (channels === 2) {
                    right = rightData.subarray(i, i + maxSamples);
                    mp3buf = mp3enc.encodeBuffer(left, right);
                } else {
                    mp3buf = mp3enc.encodeBuffer(left);
                }
            
                if (mp3buf.length > 0) {
                    buffer.push(mp3buf);
                }
            }
            
            const enc = mp3enc.flush();
            
            if (enc.length > 0) {
                buffer.push(enc);
            }
            
            return new Blob(buffer, { type: 'audio/mp3' });
        },

        sendTextMessage() {
            if (/^\s*$/.test(this.msgToSent)) {
                this.$message({
                    showClose: true,
                    message: '请不要发送空消息',
                    type: 'warning',
                });
                return;
            } if (this.msgToSent.length > 800) {
                this.$message({
                    showClose: true,
                    message: '请不要超过800个字',
                    type: 'warning',
                });
                return;
            }
            this.msgToSent = this.msgToSent.trim();
            console.log(this.scene, 'scene');
            console.log(this.to, 'to');
            console.log(this.msgToSent, 'msgToSent');
            console.log(this.sessionId, 'parseSession');
            window.$globalHub.$store.dispatch('sendMsg', {
                type: 'text',
                scene: this.scene,
                to: this.to,
                text: this.msgToSent,
            });
            this.msgToSent = '';
        },
        // eslint-disable-next-line complexity
        computedItem(rawMsg) {
            const item = Object.assign({}, rawMsg);
            if (item.type === 'timeTag') {
                // 标记发送的时间
                item.showText = item.text;
            } else if (item.type === 'text') {
                // 文本消息
                item.showText = util.escape(item.text);
                if (/\[[^\]]+\]/.test(item.showText)) {
                    const emojiItems = item.showText.match(/\[[^\]]+\]/g);
                    emojiItems.forEach(text => {
                        const emojiCnt = emojiObj.emojiList.emoji;
                        if (emojiCnt[text]) {
                            item.showText = item.showText.replace(text, `<img class="emoji-small" src="${emojiCnt[text].img}">`);
                        }
                    });
                }
            } else if (item.type === 'custom') {
                const content = JSON.parse(item.content);
                // type 1 为猜拳消息
                if (content.type === 1) {
                    const data = content.data;
                    const resourceUrl = config.resourceUrl;
                    // item.showText = `<img class="emoji-middle" src="${resourceUrl}/im/play-${data.value}.png">`
                    item.type = 'custom-type1';
                    item.imgUrl = `${resourceUrl}/im/play-${data.value}.png`;
                    // type 3 为贴图表情
                } else if (content.type === 3) {
                    const data = content.data;
                    let emojiCnt = '';
                    if (emojiObj.pinupList[data.catalog]) {
                        emojiCnt = emojiObj.pinupList[data.catalog][data.chartlet];
                        // item.showText = `<img class="emoji-big" src="${emojiCnt.img}">`
                        // item.type = 'custom-type3';
                        // item.imgUrl = `${emojiCnt.img}`;
                        item.type = 'text';
                        item.showText = `<img class="emoji-big" src="${emojiCnt.img}">`
                    }
                } else {
                    item.showText = util.parseCustomMsg(item);
                    if (item.showText !== '[自定义消息]') {
                        item.showText += ',请到手机或电脑客户端查看';
                    }
                }
            } else if (item.type === 'image') {
                // 原始图片全屏显示
                item.originLink = item.file.url;
            } else if (item.type === 'video') {
                // ...
            } else if (item.type === 'audio') {
                item.width = (5.3 + Math.round(item.file.dur / 1000) * 0.03).toFixed(2) + 'rem';
                item.audioSrc = item.file.mp3Url;
                item.showText = '<i>' + Math.round(item.file.dur / 1000) + '"</i> 点击播放';
                if (!this.isHistory) {
                    item.unreadAudio = !item.localCustom;
                }
            } else if (item.type === 'file') {
                item.fileLink = item.file.url;
                item.showText = item.file.name;
            } else if (item.type === 'notification') {
                if (item.scene === 'team') {
                    item.showText = util.generateTeamSysmMsg(item);
                } else {
                    // 对于系统通知，更新下用户信息的状态
                    item.showText = util.generateChatroomSysMsg(item);
                }
            } else if (item.type === 'tip') {
                // 对于系统通知，更新下用户信息的状态
                item.showText = item.tip;
            } else {
                item.showText = `[${util.mapMsgType(item)}],请到手机或电脑客户端查看`;
            }
            return item;
        },
        
        showTeamMember() {
            this.isShowTeamMember = !this.isShowTeamMember;
            const accIds = [];
            this.members.map(item=>{
                accIds.push(item.account);
            });
            this.$axios
                .post('/interfaceApi/message/MessageNetease/get_users_name', accIds)

                .then(res => {
                    if (res) {
                        this.membersParse = this.members.map(item=>{
                            item.alias = res[item.account];
                            return item;
                        });
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
        },
        showEmoji () {
            this.isVoice = false;
            this.isEmojiShown = !this.isEmojiShown;
        },
        showVoice () {
            this.isVoice = true;
            // this.$toast({
            //     title: false,
            //     type: 'eject',
            //     width: '6.5rem',
            //     height: '2.1rem',
            //     t_url: 'messageCenter/pages/page/components/dialogue/editDes',
            //     extr: {
            //         msgTime: 'row.take_effect_time',
            //         msgTxt: 'row.content',
            //         type: 2,
            //         title: '请编辑群组名称',
            //         // title: '请编辑群组描述',
            //         // msgTime: row.take_effect_time,
            //         // msgTxt: row.content,
            //     },
            // });
        },
        hideEmoji () {
            this.isEmojiShown = false;
        },
        addEmoji (emojiName) {
            this.msgToSent += emojiName;
            this.hideEmoji();
        },
        sendFileMsg () {
            if (this.invalid) {
                this.$message.warning(this.invalidHint)
                return
            }
            let ipt = this.$refs.fileToSent
            if (ipt.value) {
                window.$globalHub.$store.dispatch('sendFileMsg', {
                    scene: this.scene,
                    to: this.to,
                    fileInput: ipt
                })
            }
        },
        // 修改讨论组名称
        editName() {
            this.editNameDialog = true;
        },
        editSessionName() {
            const _this = this;
            
            if (/^\s*$/.test(this.newName)) {
                this.$message({
                    showClose: true,
                    message: '讨论组名称为空',
                    type: 'warning',
                });
                return;
            }
            // newSessionName = newSessionName.trim();
            window.$globalHub.$store.dispatch('delegateTeamFunction',{
                functionName: 'updateTeam',
                options: {
                    teamId: this.to,
                    name: this.newName,
                    done: (error, team)=>{
                        _this.$message.success('修改成功');
                        _this.editNameDialog = false;
                        _this.newName = '';
                    }
                }
            })
        },
        handleAddTeamMember() {
            this.showTeamChoseDialogue = true;
            const arr = [];
            this.members.map(item=>{
                arr.push(item.account);
            });
            this.existMembers = arr;
        },
        updateShowDialogue() {
            this.showTeamChoseDialogue = false;
        },
        sureMembers(data) {
            const _this = this;
            if(data.data.length>0){
                const counts = [];
                data.data.map(item=>{
                    counts.push(item.accid);
                });
                const friendslist = window.$globalHub.$store.state.friendslist;
                counts.map(item => {
                    const arr = friendslist.filter(v => v.account === item);
                    if (arr.length <= 0) {
                        window.$globalHub.$store.dispatch('addFriend', item);
                    }

                });
                const options = {
                    teamId: this.to,
                    accounts: counts,
                    done:(error, team)=>{
                        _this.isShowTeamMember = !_this.isShowTeamMember;
                    }
                };
                window.$globalHub.$store.dispatch('addTeamMember', options);
            }
        },
        // 鼠标悬浮显示移除
        changeActive($event,alias){
            if (alias!=='我') {
                $event.currentTarget.className="remove";
            }
        },
        removeActive($event){
            $event.currentTarget.className="";
        },
        // 移除群成员
        removeMember(data) {
            if (data.alias!=='我') {
                const _this = this;
                const options = {
                    teamId: this.to,
                    accounts: [data.account],
                    done:(error, team)=>{
                        _this.isShowTeamMember = !_this.isShowTeamMember;
                    }
                };
                window.$globalHub.$store.dispatch('removeTeamMember', options);
            }
        },

        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + `credential/GetPostPolicy/${this.buCode}`).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                    // localStorage.setItem('aliyunOssToken', JSON.stringify(res));
                }
            });
        },
        // 上传组名
        upGroupFun(file, filename) {
            // 判断是否过期，过期重新调用获取通信证方法
            const formData = new FormData();
            // 注意formData里append添加的键的大小写
            formData.append('key', `${this.aliyunOssToken.dir}${this.chatdata.User_Id}/${filename}`); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
            formData.append('policy', this.aliyunOssToken.policy); // policy
            formData.append('Signature', this.aliyunOssToken.signature); // 签名
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('file', file);
            // formData.append('FileDescription', this.newFile.FileDescription);
            formData.append('success_action_status', 200); // 成功后返回的操作码
            formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

            // 回调参数
            const obj = {
                bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                business_type_code: this.buCode, // 业务类型编码
                object_name: `${this.aliyunOssToken.dir}${this.chatdata.User_Id}/${filename}`, // 文件对象名
                show_name: filename, // 显示文件名
                size: 0, // 文件大小
                mimeType: '',
                mime_type: file,
                data_id: this.chatdata.User_Id, // 业务数据id
                directory_id: '', // 目录id
                tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                // FileDescription: this.newFile.FileDescription, // 文件描述
            };
            this.uploadFun(formData, obj);
        },
        // 上传文件
        uploadFun(formData, val) {
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: data => { // 成功回调
                    this.callBackFun(val);
                },
            });
        },
        // 上传的回调
        callBackFun(obj) {
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                if (res) {
                    console.log('上传成功');
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.voice_box
    height 1.2rem
    display flex
    justify-content center
    align-items center
    .voice
        text-align center
        cursor pointer
        .icon_box
            color #2CAF56
            i
                font-size .3rem
        .icon_txt
            font-size .16rem
.voice_content
    width 100%
    height 2.7rem
    text-align center
    .title
        color #222222
        font-size .18rem
    .mic_icon
        width 1.05rem
        height 1.05rem
        border 3px solid #2CAF56
        border-radius 50%
        margin .2rem auto
        cursor pointer
        i
            color #2CAF56
            font-size .7rem
    .time
        width 100%
        padding .05rem 0
        text-align center
    .btns
        display flex
        .btn
            color #999
            flex 1
            .btn_box
                i
                    font-size .28rem
                .iconzanting
                    color #FF9101
                .iconduigouxiao
                    color #2CAF56
            .btn_txt
                font-size .16rem
.currVoice
    color #2CAF56

</style>
